<template>
  <div class="card full-width">

    <div class="card-header border-0 pt-6 full-width">
      <!--begin::Card title-->
      <h4>Home Sections</h4>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-md-9"></div>
        <div class="col-md-2 mb-3">
          <button
              @click="addHomeSection()"
              type="button"
              class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary full-width"
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
              data-kt-menu-flip="top-end">
            <i class="fas fa-plus"></i>
          </button>
          <!--          <el-button @click="addHomeSection()" type="primary">Add New</el-button>-->
        </div>
        <div class="col-md-1"></div>
      </div>
      <div class="row g-5 g-xl-8" v-loading="loadingData">
        <el-empty v-if="!loadingData && homeSections.length <= 0" description="No Results Found"/>

        <div class="col-md-1"></div>
        <div class="col-md-10">
          <el-collapse>
            <draggable
                class="row dragArea row"
                :list="homeSections"
                group="people"
                itemKey="id"
            >
              <template #item="{ element , index }">
                <el-form :model="element" ref="formRef">
                  <div class="card mb-5 mt-5 mb-xl-8">
                    <div class="card-body pb-0">
                      <div class="row g-9 mb-7">
                        <div class="col-md-4 fv-row" v-for="language in languages">
                          <label class="required fs-6 fw-bold mb-2">Name ({{ language }})</label>
                          <el-form-item :prop="'original_name.'+language" :rules="rules['field']">
                            <el-input v-model="element.original_name[language]" :placeholder="`Name(${language})`"/>
                          </el-form-item>

                        </div>
                        <div class="col-md-3 fv-row">
                          <label class="required fs-6 fw-bold mb-2">Type</label>
                          <el-form-item prop="type" :rules="rules['field']">
                            <el-select class="select-display-block" clearable v-model="element.type">
                              <el-option v-for="homeSectionType in homeSectionTypes"
                                         :label="homeSectionType.label"
                                         :value="homeSectionType.value"
                                         :key="homeSectionType.value"
                              ></el-option>
                            </el-select>
                          </el-form-item>

                        </div>
                        <div class="col-md-1 fv-row">
                          <button
                              @click="deleteHomeSection(index)"
                              type="button"
                              class="btn btn-sm btn-icon btn-color-danger btn-active-light-danger"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                              data-kt-menu-flip="top-end">
                            <i class="fas fa-trash"></i>
                          </button>
                        </div>
                      </div>
                      <div class="row g-9 mb-7">
                        <div class="col-md-3 fv-row" v-if="element.type == 'services'">
                          <label class="required fs-6 fw-bold mb-2">Category</label>
                          <el-form-item prop="category_id" :rules="rules['field']">
                            <el-select class="select-display-block" clearable v-model="element.category_id">
                              <el-option v-for="category in categories"
                                         :label="category.name"
                                         :value="category.id"
                                         :key="category.id"
                              ></el-option>
                            </el-select>
                          </el-form-item>

                        </div>
                        <div class="col-md-3 fv-row" v-if="element.type != 'slider'">
                          <label class="required fs-6 fw-bold mb-2">Limit</label>
                          <el-form-item prop="num" :rules="rules['field']">
                            <el-input v-model="element.num" placeholder="Limit"></el-input>
                          </el-form-item>

                        </div>
                      </div>
                    </div>
                  </div>
                </el-form>
              </template>
            </draggable>
          </el-collapse>
        </div>
        <div class="col-md-1"></div>


      </div>
      <div class="row g-9 mb-7">
        <div class="col-md-4 fv-row">

        </div>
        <div class="col-md-4 fv-row">
          <button
              @click="validate()"
              :data-kt-indicator="loading ? 'on' : null"
              class="btn btn-lg btn-primary full-width"
              type="button"
              :disabled="loading">
              <span v-if="!loading" class="indicator-label">
                Save
              </span>
            <span v-if="loading" class="indicator-progress">
                Please wait...
                <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
          </button>
        </div>
        <div class="col-md-4 fv-row">

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, ref, onMounted, watch, reactive} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import ApiAxiosService from "@/core/services/ApiAxiosService";
import {Search, Edit, Delete, View} from '@element-plus/icons-vue'
import {handleError, handleSuccess} from "@/core/helpers/response";
import {APIs} from "@/store/enums/ApiEnums";
import {confirmDelete, confirmGeneralMessage} from "@/core/helpers/custom_notification";
import {useRouter, useRoute} from 'vue-router'
import Swal from "sweetalert2/dist/sweetalert2.js";
import Draggable from "vuedraggable";

export default defineComponent({
  name: "home-sections-list",
  components: {
    Draggable
  },

  setup() {
    const router = useRouter();
    const homeSections = ref([]);
    const homeSectionTypes = ref([]);
    const categories = ref([]);
    const languages = ref(['ar', 'en']);
    const formRef = ref(null);
    const rules = ref({
      field: [
        {
          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
    });
    onMounted(() => {
      setCurrentPageBreadcrumbs("Home Sections", []);
    });
    const loadingData = ref(false);
    const loading = ref(false);

    const fetchData = async () => {
      loadingData.value = true;
      try {
        let response = await ApiAxiosService.get(APIs.HOME_SECTION.list);
        homeSections.value = response.data.data.home_sections;
        homeSectionTypes.value = response.data.data.home_section_types;
        categories.value = response.data.data.categories;
        languages.value = response.data.data.languages;
        loadingData.value = false;
      } catch (error) {
        handleError(error)
      }

    };

    const addHomeSection = () => {
      homeSections.value.push({
        original_name: {en: '', ar: ''},
        type: '',
        category_id: '',
        num: ''
      });
    }
    const deleteHomeSection = (index) => {
      homeSections.value.splice(index, 1);
    }

    const validate = () => {
      if(formRef.value) {
        formRef.value.validate((valid) => {
          if (valid) {
            save();
          } else {
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          }
        });
      }else {
        save();
      }
    }
    const save = () => {
      var formData = new FormData();
      formData.append('home_sections', JSON.stringify(homeSections.value))
      loading.value = true;
      ApiAxiosService.post(APIs.HOME_SECTION.list, formData).then(function (res) {
        homeSections.value = res.data.data.home_sections;
        loading.value = false;
        handleSuccess(res);
      }).catch(function (err) {
        loading.value = null;
        handleError(err);
      });


    }
    fetchData();
    return {
      homeSections,
      homeSectionTypes,
      categories,
      loading,
      loadingData,
      languages,
      formRef,
      rules,
      addHomeSection,
      deleteHomeSection,
      save,
      validate
    };
  },

});
</script>

<style>
.el-collapse {
  border-bottom: none;
  border-top: none;
}
</style>
